.landing-body{
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px); 
  min-height: 800px;  
  max-height: 900px;
}

.landing-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
  padding: 20px;
   
}
.landing-content p{
  font-size: 17px;
}
.landing-content h2{ 
  text-align: center;
  font-size: 35px;  
  padding: 20px;
  font-family: 'Tienne', serif;
}
.landing-content h2 span{ 
  color: #0913FA;
  font-family: 'Tienne', serif;
}
.three-lables{
  display: flex;
  justify-content: center;
  align-items: center;
}
.three-lables div{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 3px solid black;
  height: 260px;
  width: 170px;
  margin: 0px 40px;
 /* padding: 10px;*/
  border-radius:20px;
  margin-top: 10px;
}
.three-lables div img{
  width: 120px;
}
.three-lables div p{
  font-size: 18px; 
}
.links {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: black;  
}
.footer-body{
  background-color:rgb(162, 159, 159);
  height: 500px;
  width: 100%;
  color:black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.footer-section {
  margin: 20px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-size: 30px;  
}
.footer-callus{
  margin-top: 0;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-size: 30px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-icons{
  text-align: center;
  margin-top: 220px;
  color: #2126cd;
}
.copyright{
  font-weight: bold;
}
.para{
  color: black;
  font-size: 18px;
 text-align: center;
}
.p_li{
  color: black;
  font-size: 18px;
  margin: 0;
}
.three-lables img:hover{
  filter: grayscale(1);
  transform: scale(1.3) rotate(7deg);
  cursor: pointer;
}
@media (max-width: 999px) {
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 260px;
    width: 170px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
  }
  .three-lables div img {
    width: 100px;
    padding: 2px;
  }
  .three-lables div p {
    font-size: 18px;
    
  }
  .links {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .landing-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px; 
  }
  .landing-content p{
    font-size: 17px;
  }
  .landing-content h2{ 
    text-align: center;
    font-size: 25px;  
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif; 
    color: #0913FA;
  }
}
@media (max-width: 1010px) {
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px;
    padding: 15px;
  }
  .landing-content p {
    font-size: 13px;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px;
    padding: 15px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 250px;
    width: 170px;
    margin: 0px 20px;
    border-radius: 20px;
  }
  .three-lables div img {
    width: 120px;
  }
  .three-lables div p {
    font-size: 18px;
    
  }
  .links {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
}


@media(max-width:930px){
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 230px;
    width: 150px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
  }
  .three-lables div img {
    width: 100px;
    padding: 2px;
  }
  .three-lables div p {
    font-size: 17px;
    
  }
  .links {
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .landing-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px; 
  }
  .landing-content p{
    font-size: 17px;
  }
  .landing-content h2{ 
    text-align: center;
    font-size: 25px;  
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{ 
    color: #0913FA;
    font-family: 'Tienne', serif;
  }
}

@media (max-width: 870px){
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    gap: 10px;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 210px;
    width: 150px;
    margin: 10px 10px;
    border-radius: 20px;
  }
  .three-lables div img {
    width: 110px;
  }
  .three-lables div p {
    font-size: 18px;
  }
  .links {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
}
@media (max-width:780px){
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin: 0px;
    display: grid;
    grid-template-columns: 15rem 15rem;
    gap: 20px;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 210px;
    width: 160px;
    margin: 10px 10px;
    border-radius: 20px;
    margin-left: 40px;
  }
  .three-lables div img {
    width: 110px;
  }
  .three-lables div p {
    font-size: 18px;
    
  }
  .links {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }

}
@media (max-width: 680px){
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin: 0px;
    display: grid;
    grid-template-columns: 13rem 13rem;
    gap: 20px;
    
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 200px;
    width: 170px;
    margin: 10px 10px;
    border-radius: 20px;
  }
  .three-lables div img {
    width: 120px;
  }
  .three-lables div p {
    font-size: 18px;
    
  }
  .links {
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    color: black;
  }

}
@media (max-width: 599px) {
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 13rem 13rem;
    gap: 2rem;
    
  }
  .three-lables div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 180px;
    width: 140px;
    margin: 20px 20px;
    border-radius: 20px;
    margin-left: 40px;
  }
  .three-lables div img {
    width: 90px;
  }
  .three-lables div p {
    font-size: 16px;
  }
  .links {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .footer-icons{
    margin-top: 130px;
  }
  .footer-callus{
    margin-top: 20px;
  }
 
 
}
@media (min-width:401px) and (max-width:480px){
  .three-lables {
    gap: 1rem;
    grid-template-columns: 10rem 10rem;
    margin-right: 55px;
  }
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 25px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .footer-callus{
    margin-top: 20px;
  }
}
  


@media (max-width: 400px) {
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 15px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 20px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  
  .three-lables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0%;
    gap: 0%;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 130px;
    width: 100px;
    border-radius: 20px;
    margin-bottom: auto;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: 10px;    
  }
  .three-lables div img {
    width: 80px;
   
  }
  .three-lables div p {
    font-size: 13px;
    
  }
  .links {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  } 
  .footer-icons{
    margin-top: 100px;
  }
  .footer-body{
    margin-top:0%;
  }
  .footer-callus{
    margin-top: 20px;
  }
}
@media (max-width: 359px){
  .three-lables {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    gap: 1rem;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 130px;
    width: 100px;
    margin: 0px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .three-lables div img {
    width: 80px;
  }
  .three-lables div p {
    font-size: 13px;
  }
  .links {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 14px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 20px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .footer-callus{
    margin-top: 20px;
  }
}
@media (max-width: 329px) and (min-width:272px) {
  .three-lables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 20px;
    gap: 0.5rem;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 130px;
    width: 100px;
    margin: 4px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-right: 30px;
  }
  .three-lables div img {
    width: 80px;
  }
  .three-lables div p {
    font-size: 13px;
  }
  .links {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
  }
  .landing-content p {
    font-size: 13px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 20px !important;
    padding: 10px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
  .footer-icons{
    margin-top: 70px;
    margin-bottom: 2px;
  } 
  .para{
    font-size: 18px;
    
  }
  .p_li{
    font-size: 18px;
  }
  .footer-callus{
    margin-top: 20px;
  }
}
@media (max-width: 271px) {
  .three-lables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 0;
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 120px;
    width: 100px;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .three-lables div img {
    width: 80px;
  }
  .three-lables div p {
    font-size: 13px;
    text-align: center;
  }
  .links {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 10px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 15px !important;
    padding: 8px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }
 
  .footer-body{
    background-color:rgb(162, 159, 159);
    height: 400px;
    width: 100%;
    color:black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .footer-section {
    margin: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
    font-size: 30px;  
  }
  .footer-callus{
    margin-top: 0;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
    font-size: 30px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer-icons{
    margin-top: 10px;
    color: #2126cd;
    size: 20;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 1px;
  }
  .copyright{
    font-weight: 800;
  }
  .para{
    color: black;
    font-size: 16px;
   text-align: center;
   
  }
  .p_li{
    color: black;
    font-size: 16px;
    margin: 0;
  }
  .footer-callus{
    margin-top: 18px;
  }
  
}
@media (max-width: 229px) {
  .three-lables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 0;
    gap: 1rem;
    /*display: grid;*/
    /*grid-template-columns: 4rem 4rem;*/
  }
  .three-lables div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
    height: 110px;
    width: 90px;
    margin: 4px 4px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .three-lables div img {
    width: 70px;
    /*padding: 1px;*/
  }
  .three-lables div p {
    font-size: 13px;
    text-align: center;
    padding: 1px;
  }
  .links {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .icons{
    font-size: 10px;
  }
  .landing-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px !important;
    padding: 2px;
  }
  .landing-content p {
    font-size: 10px !important;
  }
  .landing-content h2 {
    text-align: center;
    font-size: 15px !important;
    padding: 8px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{
    font-family: 'Tienne', serif;
  }

  .footer-body{
    background-color:rgb(162, 159, 159);
    height: 400px;
    width: 100%;
    color:black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .footer-section {
    margin: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
    font-size: 30px;  
  }
  .footer-callus{
    margin-top: 0;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: 900;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer-icons{
    margin-top: 30px;
    font-size: 20px;
    color: #2126cd;
    font-size: 15px;
  }
  .copyright{
    font-weight: bold;
  }
  .para{
    color: black;
    font-size: 15px;
   text-align: center;
  }
  .p_li{
    color: black;
    font-size: 15px;
    margin: 0;
  }
  .footer-callus{
    margin-top: 10px;
  }
}
@media (min-width:198px) and (max-width:228px){
  .three-lables div p {
    font-size: 10px;
    text-align: center;
    padding: 1px;
  }
  .links{
    font-size: 12px;
    font-weight: 600;
  }
  .footer-section .p_li{
    font-size: 10px;
  }
   .footer-callus .p_li{
    color: black;
    font-size: 10px;
  }
  .footer-callus{
    margin-top: 10px;
  }

  
}



    
  
  
 




  
  
  
  

  












 