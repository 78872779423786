.loader-wrapper{
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left:0;
   display: grid;
   place-items: center;
}
   .loader{
      border: 16px solid #d3d6f8 ;
      border-top: 16px solid blue;
height: 100px;
width:100px;
border-radius: 50%;
animation: spin 1s linear infinite;
   }
@keyframes spin {
   0%{
      transform: rotate(0deg);
   }
   100%{
      transform: rotate(360deg);
   }
}
.loader-div{
   display: flex;
   flex-direction: row;
   column-gap: 20px;
}
.text{
   font-size: 30px;
   padding-top: 30px;
   color: blue;
}