@import url('https://fonts.googleapis.com/css2?family=Tienne:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@550&display=swap');
html {
	height: 100%;

	body {
		height: 100%;
		background-color: #f0f0f0;  
		font-family: 'Inter', sans-serif; 
	}
}
