.navbar-div{
   position: absolute;
   top: 0;left: 0;
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;  
   padding: 10px 5%;  
}

.navbar-div .logo h3{
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 800;
}

.navbar-div .nav{ 
   flex: 1;  
   margin-left: 30px;
   align-items: center;  
   justify-content: flex-start;
}

.navbar-div .nav a{
   text-decoration: none;
   margin: 0px 20px;
   color: black;
   font-size: 17px;
   font-weight: 600; 
}

.order-user-detail{
   display: flex;
   justify-content: center;
   align-items: center; 
}

.order-user-detail img{
   width: 50px;
   border-radius: 50px;
   margin: 0px 20px;
}

.order-user-detail p{ 
   font-size: 17px; 
   margin: 0px;
   font-weight: 600; 
}