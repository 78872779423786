:root {
	--bs-blue: #5e72e4;
	--bs-indigo: #5603ad;
	--bs-purple: #8965e0;
	--bs-pink: #f3a4b5;
	--bs-red: #f5365c;
	--bs-orange: #fb6340;
	--bs-yellow: #ffd600;
	--bs-green: #2dce89;
	--bs-teal: #11cdef;
	--bs-cyan: #2bffc6;
	--bs-white: #fff;
	--bs-gray: #8898aa;
	--bs-gray-dark: #32325d;
	--bs-light: #ced4da;
	--bs-lighter: #e9ecef;
	--bs-primary: #5e72e4;
	--bs-secondary: #f7fafc;
	--bs-success: #2dce89;
	--bs-info: #11cdef;
	--bs-warning: #fb6340;
	--bs-danger: #f5365c;
	--bs-light: #adb5bd;
	--bs-dark: #212529;
	--bs-default: #172b4d;
	--bs-white: #fff;
	--bs-neutral: #fff;
	--bs-darker: black;
	--bs-primary-rgb: 94, 114, 228;
	--bs-secondary-rgb: 247, 250, 252;
	--bs-success-rgb: 45, 206, 137;
	--bs-info-rgb: 17, 205, 239;
	--bs-warning-rgb: 251, 99, 64;
	--bs-danger-rgb: 245, 54, 92;
	--bs-light-rgb: 173, 181, 189;
	--bs-dark-rgb: 33, 37, 41;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-breakpoint-xs: 0;
	--bs-breakpoint-sm: 576px;
	--bs-breakpoint-md: 768px;
	--bs-breakpoint-lg: 992px;
	--bs-breakpoint-xl: 1200px;
	--bs-font-family-sans-serif: 'Open Sans', sans-serif;
	--bs-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
		'Courier New', monospace;
}
