/* src/components/AboutUs.css */
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
 
}
.hero{
  background-color: #f8f8f8;
  overflow: hidden;
}
.heading h1{
  color: blue;
  font-size: 55px;
  text-align: center;
}
.container{
  display: flex;
  justify-content: center;
  text-align: center;
  width: 90%;
  margin: 65px auto;
}
.hero-content{
  flex: 1;
  width: 600px;
  margin: 0px 25px;
  animation: fadeInUp 2s ease;
}
.hero-content h2{
  font-size: 38px;
  margin-bottom: 20px;
  color: #333;
}
.hero-content p{
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 40px;
  color: #666;
}
.hero-content button{
  display: inline-block;
  background-color: rgb(96, 96, 248);
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
}
.hero-content button:hover{
  background-color: blue;
   transform: scale(1.1);
}
.hero-image{
  flex: 1;
  width: 600px;
  margin: auto;
  animation: fadeInRight 2s ease; 
}
img{
  width: 100%;
  height: auto;
  border-radius: 10px;
}



@media screen  and (max-width:768px){
  .heading h1{
    font-size: 25px;
 /*   margin-top: 30px;*/
  }
  .hero{
    margin: 0px;
  }
  .container{
    width: 100%;
    flex-direction: column;
    margin: 0px;
    padding: 0px 40px;
  }
  .hero-content{
    width: 100%;
    margin: 35px 0px;
  }
  .hero-content h2{
    font-size: 30px;
  }
  .hero-content p{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .hero-content button{
    font-size: 16px;
    padding: 8px 16px;
  }
  .hero-image{
    width: 100%;
  }
  .profile-row1{
    font-size: 15px;
  }
}

@keyframes fadeInUp{
  0%{
    opacity: 0;
    transform: translateY(50px);

  }
  100%{
    opacity: 1;
    transform: translateY(0px);
    
  }

}
@keyframes fadeInRight{
  0%{
    opacity:0 ;
    transform: translateY(-50px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
  
}









/*.about-us {
   max-width: 1200px;
   margin: 40px auto;
   padding: 20px;
   text-align: center;
   background-image: url("../../assets/img/bg.jpg");
   color:blue;   
 } 
 .list{
  list-style: none;
  text-align: center;  
 }
 */
 
 
 




