// $enable-important-utilities: true;

// $utilities: map-merge(
// 	$utilities,
// 	(
// 		'width':
// 			map-merge(
// 				map-get($utilities, 'width'),
// 				(
// 					responsive: true,
// 				)
// 			),
// 		'height':
// 			map-merge(
// 				map-get($utilities, 'height'),
// 				(
// 					responsive: true,
// 				)
// 			),
// 	)
// );

.h-15 {
	height: 15% !important;
}

.h-85 {
	height: 85% !important;
}

@media (min-width: 768px) {
	.h-md-100 {
		height: 100% !important;
	}
}

// for customization page

.visible-on-999 {
	display: none;
}

@media only screen and (max-width: 999px) {
	.invisible-on-999 {
		display: none !important;
	}
	.visible-on-999 {
		display: block;
	}
 }
