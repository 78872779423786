*{
   margin: 0px;
   padding: 0px;
  box-sizing: border-box;
 /* background-image:'url(${bgimage})';*/
   /*font-family: sans-serif;*/
   font-family: Arial, Helvetica, sans-serif;
}
body{
   width: 100%;
  
}
.arrow-back{
   margin-top: 3px;
}
.back-button{
   font-size: 25px;
}
.title h1{
   text-align: center;
   /*padding-top: 50px;*/
   font-size: 42px;
   margin-right: 10px;
}
.title h1::after{
   content: "";
   height: 4px;
   width: 230px;
   display: block;
   margin: auto;
}
.services{
   width: 85%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 75px auto;
   text-align: center;
}
.card{
   width: 100%;
   display: flex;
   justify-content: space-between;
   text-align: center;
   flex-direction: column;
   margin: 0px 20px;
   padding: 20px 20px;
   background-color: #efefef;
   border-radius: 10px;
   cursor: pointer;
}
.card:hover{
   background-color: #b8d4de;
   transition: 0.4s ease;
}
.card .icon{
   font-size: 35px;
   margin-bottom: 10px;
}
.card h2{
   font-size: 28px;
   color: blue;
   margin-bottom: 20px;
}
.card p{
   font-size: 17px;
   margin-bottom: 30px;
   line-height: 1.5;
   color: #5e5e5e;
}
.button{
   background-color: #2c677c;
   font-size: 15px;
   text-decoration: none;
   color: #fff;
   padding: 8px 12px;
   border-radius: 5px;
   letter-spacing: 1px;

}
.button:hover{
   background-color: #022f40;
   transition: 0.4s ease;

}

@media screen and (max-width:940px){
   .services{
      display: flex;
      flex-direction: column;
   }
   .card{
      width: 85%;
      display: flex;
      margin: 20px 0px;
   }
}
@media screen  and (max-width:768px){
   .title h1{
      text-align: center;
      font-size: 25px;
      
   }  
}





