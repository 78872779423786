body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: Arial, Helvetica, sans-serif;
   background-image: url("../../assets/img/bg.jpg");
   background-size: cover;
   height: 150vh;
   /*style={{backgroundImage:`url(${bgimage})`,backgroundSize:'cover',height:'150vh'}}*/
}

header {
   color:blue;
   padding: 10px;
   text-align: center;
   
}

main {
   padding: 20px;
}

.product {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   
}

.product-item {
   width: 300px;
   height: 400px;
   margin: 20px;
   /*margin-top: 20px;*/
   border: 1px solid #ccc;
   padding: 10px;
   text-align: center;
   border: 3px solid black;
   border-radius: 20px;
   
}
.label{
   font-size: 20px;
   margin-top: 8px;
   
}
.product-btn{
   /*width: 100% ;*/
   margin-top: -2px; 
  border: none;
  outline: none;
   border-radius: 10px;
   padding: 8px;
   font-size: 17px;
   cursor: pointer; 
   padding: 10px 10px;
   background-color:rgb(103, 143, 196);
   font-weight: 800;
   color:black;
}
.product-btn:hover{
   transform: scale(1.1);
   background-color:rgb(80, 143, 226);

}
.product-item img{
   width: 200px;
   height: 250px;
   margin: 20px;
   mix-blend-mode: multiply;
   
 
}
.profile-row1{
   float: left;

}
@media screen and (max-width: 768px) {
   .product-item {
      width: 300px;
      height: 400px;
      margin: 20px;
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
      border: 3px solid black;
      border-radius: 20px;
   }
   .product-item img{
      width: 200px;
      height: 250px;
      margin: 20px;
   }
}
@media (max-width:320px){
   body{
      height: 200vh;
   }
   header {
      margin-top: 20px;
      color:blue;
      padding: 2px;
      text-align: center;
   }
   main {
      padding: 5px;
   }
   .product {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;  
   }
   
   .product-item {
      width: 250px;
      height: 300px;
      margin: 10px;
      border: 1px solid #ccc;
      padding: 5px;
      text-align: center;
      border: 3px solid black;
      border-radius: 20px;
   }
   .label{
      font-size: 15px;
      margin-top: 15px;
      
   }
   .product-btn{
         /*width: 100% ;*/
         margin-top: -2px; 
        border: none;
        outline: none;
         border-radius: 10px;
         padding: 8px;
         font-size: 17px;
         cursor: pointer; 
         padding: 10px 10px;
         background-color:rgb(103, 143, 196);
         font-weight: 800;
         color:black;
      }
      .product-btn:hover{
         transform: scale(1.1);
         background-color:rgb(80, 143, 226);
      
      }
      /*width: 100% ; 
      border: none;
      outline: none;
       border-radius: 10px;
       padding: 8px;
       font-size: 17px;
       background-color: blue;
       font-weight: 800;
       color: white;
      margin-top: 30px;*/
   
   .product-item img{
      width: 130px;
      height: 150px;
      margin: 10px;
   }
   .profile-row1{
      float: left;
   
   }
   .header h1{
      font-size: 15px !important;
      top: -5px;
      
   }
}














/* *{
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

.h1{
   text-align: center;
   color: blue;
   font-weight: bold;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.img{
   height: 250px;
   width: 230px;
   cursor: pointer;
   border-radius: none;
  /*margin-left: 50px;*/


/*   .picture{
      display: flex;
      margin: 20px;
      flex-flow:row;
      flex-wrap: wrap;
   }
   .flex-one{
      display: flex;
      flex-direction: column;
      margin: 20px;
      box-sizing: border-box;
   }
 .button-products{
   background-color: rgb(101, 78, 217);
   text-align: center; 
  
 }
 .btn{
  color: white !important;
   width: auto;
   height: auto;
 }
 .label-name{
   text-align: center;
   color: black;
   font-size: 20px;
   background-color:white;
  /* margin-left: 50px;*/
 

 
/*@media screen and (max-width:520px){
      .picture{
         display: flex;
         margin: 20px;
         flex-flow:row;
         flex-wrap: wrap;
         text-align: center;
         margin-left: 140px;
      }
      .img{
         height: 200px;
         width: 200px;
         cursor: pointer;
         
         }  
   
      }
      @media screen and (min-width:321px) and (max-width:480px){
         .picture{
            display: flex;
            margin: 20px;
            flex-flow:row;
            flex-wrap: wrap;
            text-align: center;
            margin-left: 80px;
         }
         .img{
            height: 170px;
            width: 170px;
            cursor: pointer;
            } 
      }

      @media screen and (max-width:320px) {
         .picture{
            display: flex;
            margin: 20px;
            flex-flow:row;
            flex-wrap: wrap;
            text-align: center;
            margin-left: 60px;
         }
         .img{
            height: 150px;
            width: 150px;
            cursor: pointer;
            } 
      }*/
   


      
      
      

   
 

