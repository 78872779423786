main.custom-home {
	.fade-enter {
		opacity: 0;
		transform: scale(0.9) translateY(100%);
	}
	.fade-enter-active {
		opacity: 1;
		transform: translateX(0) translateY(0);
		transition: opacity 500ms, transform 1000ms;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
		transform: scale(0.9) translateY(100%);
		transition: opacity 500ms, transform 1000ms;
	}
}
