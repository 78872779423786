.setting-items{
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: column;
}

.setting-items div{
   display: flex;
   justify-content: flex-start;
   align-items: flex-start; 
   font-size: 18px; 
   padding: 20px 0px;
   font-weight: 700;
   color: gray;
   margin-left: 10px;
}

.setting-items div:hover,.setting-items div.active{ 
   color: blue;
}
 
.setting-body-div{ 
   width: 250px;
}

.setting-section{
   flex: 1; 
   padding: 0px 30px; 
   margin: 30px; 
   border-left: 1px solid rgb(172, 172, 172);
   height: 80vh;
   min-height: 800px !important; 
}
 