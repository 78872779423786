.sign-main-div{
  width: 100%;
  height: 100vh;
  min-height: 800px; 
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row-reverse; 
  max-height: 900px;
}

.sign-main-div .img-div{ 
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
}

.sign-main-div img{
  width: 70%;  
  max-width: 500px;
  min-width: 400px;
  margin: 0px 5%;
}

.sign-main-div .body-content{ 
  width: 400px;
  padding: 40px;
  margin:40px 5%; 
}

.signin-main-div .body-content p{
  font-weight: 900 !important; 
}

.sign-main-div .body-content h4{
  font-family: 'Tienne', serif;
  font-size: 25px;
}

.sign-main-div .body-content label{ 
  width: 100% ;
  font-weight: 700;  
}

.sign-main-div .body-content label input{ 
  width: 100% ;
  border: 1px solid gray;
  outline: none;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 17px;
}

.sign-main-div .body-content label.checkbox-input {
  font-size: 15px; 
  display: flex; 
  align-items: center; 
}

.sign-main-div .body-content label.checkbox-input input{ 
  width: 20px ; 
  height: 20px;
  border-radius: 5px;
  margin-right: 10px; 
}

.sign-main-div .body-content button{ 
  width: 100% ; 
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 8px;
  font-size: 17px;
  background-color: blue;
  font-weight: 800;
  color: white;
}

.sign-main-div .body-content div{ 
  width: 100% ;   
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.sign-main-div .body-content div .icon{ 
  color: blue;
  font-size: 30px;
}
.profile-row1{
  font-size: 1.4em;
  padding-left: 5%;
  padding-top:10px ;
  font-weight: 400;
  color: #3A3A44;
  font-weight: bolder;
}

/* @media (max-width: 1000px) {
  .img-div {
    opacity: 0.45;
  }
} */



@media (min-width: 1000px) {
  /* .img-div {
    background: url('../../assets/img/bg2.png') center/cover no-repeat;
    opacity: 0.35;
  } */

  .body-content{
    padding: 70px;
  }
}


/*.sign-main-div{
  width: 100%;
  height: 100vh;
  min-height: 800px; 
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row-reverse;  
  max-height: 900px;
}

.sign-main-div .img-div{ 
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sign-main-div img{
  width: 70%;  
  max-width: 700px;
  min-width: 600px;
  margin: 0px 5%;
}

.sign-main-div .body-content{ 
  width: 600px;
  padding: 60px;
  margin:40px 5%;
  margin-left: 10px; 
}

.signin-main-div .body-content p{
  font-weight: 900 !important; 
}

.sign-main-div .body-content h4{
  font-family: 'Tienne', serif;
  font-size: 25px;
}

.sign-main-div .body-content label{ 
  width: 100% ;
  font-weight: 700;  
}

.sign-main-div .body-content label input{ 
  width: 100% ;
  border: 1px solid gray;
  outline: none;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 17px;
}

.sign-main-div .body-content label.checkbox-input {
  font-size: 15px; 
  display: flex; 
  align-items: center; 
}

.sign-main-div .body-content label.checkbox-input input{ 
  width: 20px ; 
  height: 20px;
  border-radius: 5px;
  margin-right: 10px; 
}

.sign-main-div .body-content button{ 
  width: 100% ; 
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 8px;
  font-size: 17px;
  background-color: blue;
  font-weight: 800;
  color: white;
}

.sign-main-div .body-content div{ 
  width: 100% ;   
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.sign-main-div .body-content div .icon{ 
  color: blue;
  font-size: 30px;
}
@media (max-width:320px) {
  .sign-main-div .img-div{ 
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    position:absolute;
  }
  
  .sign-main-div img{
    width: 30%;  
    /*max-width: 2%;*/
    /*min-width: 250px;
    margin-top: 35px;
    margin-left: -5px;
    margin-right: 5px;*/
    /*margin: 0px 5%;*/
    /*margin-top: -400px;*/
    
  
  /*.sign-main-div .body-content{
   margin-top: 300px;
    width: 300px;
    padding: 60px;
    margin-left: 10px;
  }  
  .sign-main-div .body-content h4{
    font-family: 'Tienne', serif;
    font-size: 20px;
  }
  .sign-main-div .body-content p{
    font-size: 8px !important;
    font-weight: 900 !important; 
  }
  .sign-main-div .body-content label{ 
    width: 100% ;
    font-weight: 800;
      
  }
  
  .sign-main-div .body-content label input{ 
    width: 100% ;
    border: 1px solid gray;
    outline: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
  }
  
  .sign-main-div .body-content label.checkbox-input {
    font-size: 15px; 
    display: flex; 
    align-items: center; 
  }
  
  .sign-main-div .body-content label.checkbox-input input{ 
    width: 30px ; 
    height: 20px;
    border-radius: 5px;
    margin-right: 10px; 
  }
  
  .sign-main-div .body-content button{ 
    width: 100% ; 
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 8px;
    font-size: 17px;
    background-color: blue;
    font-weight: 700;
    color: white;
  }  
  .sign-main-div .body-content div .icon{ 
    color: blue;
    font-size: 20px;
  }
  
   
}*/







