 
.mainDiv{
	width: 100%;    
} 
 
.items-div{  
	display: flex;
	justify-content: flex-start;  
	overflow-x: auto;
   flex-wrap: wrap;
} 

.items-div .item{
	height: 290px;  
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 15px; 
}

.items-div .item img{
	height: 180px;   
	border-radius: 20px;
}

.items-div .item .item-title {  
	display: flex;
	justify-content: space-between; 
	align-items: flex-start; 
	width: 100%;
	padding: 0px 10px;
}

.items-div .item .item-title h6 {  
	font-size: 18px !important;
	font-weight: 700; 
}

.items-div .item .item-title p {  
	color: gray;
}

.items-div .item .item-title b {  
	font-size: 16px; 
	padding: 5px; 
	color: gray;
}

.header-drop-filter{
	display: flex; 
	justify-content: space-between;
	align-items: center; 
}

.header-drop-filter div span{ 
	background-color: rgb(240, 240, 240);
	padding: 5px 15px;
	border-radius: 30px;
	font-size: 17px; 
}

.header-drop-filter div .menu-icon{  
	background-color: rgb(207, 212, 255);
	padding: 5px;
	height: 30px;
	width: 40px;
	font-size: 30px;
	margin-top: -5px;
	border-top-left-radius: 30px;
	border: 1px solid rgb(130, 131, 134);
	border-bottom-left-radius: 30px;
}

.header-drop-filter div .bar-icon{  
	background-color: rgb(235, 235, 235);
	padding: 5px;
	height: 30px;
	width: 40px;
	font-size: 30px;
	margin-top: -5px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}


.header-drop-filter p{
	font-size: 40px;
	font-weight: 700;
	padding: 20px 0px;
}