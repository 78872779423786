.profile-container{
   /* background-color: red !important; */
   width: 100%;
   height: 100%;
   z-index: 255; 
}

.profile-body{
   position: absolute;
   top: 60px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: flex-start;
}

.sidebar{ 
   margin: 40px ;
}
 
.outlet{
   flex: 1; 
   margin-right: 50px; 
} 


.mainDiv{
	width: 100%;    
}

.mainDiv .menu-div{
	margin: 40px 0px;
	height: 50px; 
	display: flex;
	justify-content: flex-start;
	align-items: center; 
	position: relative;
}

.mainDiv .menu-div::before{
	content: "";
	bottom: 0;
	left: 0;
	position: absolute; 
	height: 2px;  
	background-color: rgb(215, 215, 215);
	width: 100%;  
	mix-blend-mode: multiply;
}

.mainDiv .menu-div button{ 
	width: 100px;
	height: 100%;
	font-size: 20px;
	margin-right: 30px;
	border: none;
	color: gray; 
	background-color: transparent; 
}

.mainDiv .menu-div button.active,.mainDiv .menu-div button:hover{  
	border-bottom: 2px solid blue;
	color: black;
}

.items-div{  
	display: flex;
	justify-content: flex-start;   
   flex-wrap: wrap;
} 

.items-div .item{ 
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-direction: column;  
}

.items-div .item img{
	height: 180px;   
	border-radius: 20px;
}
 
.items-div .item .item-title  { 
	padding-top: 15px;
}
 
.items-div .item .item-title h6 { 
	font-size: 18px !important;
	font-weight: 700; 
}


.header-drop-filter{
	display: flex; 
	justify-content: space-between;
	align-items: center; 
}

.header-drop-filter div span{ 
	background-color: rgb(240, 240, 240);
	padding: 5px 15px;
	border-radius: 30px;
	font-size: 17px; 
}

.header-drop-filter div .menu-icon{  
	background-color: rgb(207, 212, 255);
	padding: 5px;
	height: 30px;
	width: 40px;
	font-size: 30px;
	margin-top: -5px;
	border-top-left-radius: 30px;
	border: 1px solid rgb(130, 131, 134);
	border-bottom-left-radius: 30px;
}

.header-drop-filter div .bar-icon{  
	background-color: rgb(235, 235, 235);
	padding: 5px;
	height: 30px;
	width: 40px;
	font-size: 30px;
	margin-top: -5px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}


.header-drop-filter p{
	font-size: 40px;
	font-weight: 700;
	padding: 20px 0px;
}

